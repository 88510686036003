const capacityTypeMap = {
    GEMINI_MAINLINERS: "Gemini Mainliners",
    GEMINI_SHUTTLE: "Gemini Shuttle",
    NON_GEMINI_MAINLINER: "Non-Gemini Mainliner",
    FEEDER_OWN: "Feeder (Own)"
};

const reverseCapacityTypeMap = Object.fromEntries(
    Object.entries(capacityTypeMap).map(([key, value]) => [value, key])
);

export const mapCapacityType = (capacityType) => {
    return capacityTypeMap[capacityType] || capacityType;
};

export const mapLabelToCapacityType = (label) => {
    return reverseCapacityTypeMap[label] || label;
};


